<template>
  <div
    v-loading="loading"
    class="r-modal-card"
  >
    <div class="r-modal-card__controls">
      <r-button
        type="primary"
        :disabled="disabled"
        @click="create"
      >
        Создать
      </r-button>
      <r-button
        simple
        @click="close"
      >
        Отмена
      </r-button>
    </div>
    <r-modal-card-block
      icon="users"
      title="Контактные лица"
    >
      <attributes-list
        :model="potItemModel.filter(m => m.block === 'contacts')"
        :related="related"
        :source="source"
        is-editing
        @hasChanges="hasChanges = false"
      />
    </r-modal-card-block>
    <r-modal-card-block
      icon="cargo"
      title="Груз"
      transparent
    >
      <attributes-list
        :model="potItemModel.filter(m => m.block === 'cargo')"
        :related="related"
        :source="source"
        is-editing
        @hasChanges="hasChanges = false"
      />
    </r-modal-card-block>
  </div>
</template>

<script>
import attributesList from '@/components/globals/r-modal-elements/attributes-list'
import rModalCardBlock from '@/components/globals/r-modal-elements/r-modal-card-block'
import { potItemModel, cargoSource } from '../configs'
import { notifyFactory } from '@/utils'

export default {
  components: { attributesList, rModalCardBlock },
  data() {
    return {
      loading: false,
      source: cargoSource(),
      hasChanges: false,
      source_id: this.$store.state.services.cargoes || null,
      potItemModel
    }
  },
  computed: {
    related() {
      return this.$store.state.pot1.related
    },
    disabled() {
      return !this.source.packing_list_no && !this.hasChanges
    }
  },
  created() {
    if (!this.source_id) {
      this.$notify(
        notifyFactory('error', 'Произошла ошибка, обратитесь к администратору')
      )
      this.close()
    }
  },
  methods: {
    async create() {
      this.loading = true

      try {
        await this.$store.dispatch('PUT_REQUEST', {
          url: `objectInfo/${this.source_id}`,
          data: this.source
        })
        this.$notify(
          notifyFactory('success', 'Груз успешно создан')
        )
        this.$emit('update-list', 'cargo')
        this.close()
      } catch (e) {
        throw new Error(e)
      } finally {
        this.loading = false
      }
    },
    close() {
      const title = this.$t('before:title')
      const message = this.$t('before-cancel:text')
      const confirmButtonText = this.$t('button-confirm')
      const cancelButtonText = this.$t('button-cancel')

      if (this.hasChanges) {
        this.$confirm(message, title, {
          customClass: 'r-message-box',
          closeOnPressEscape: true,
          closeOnClickModal: false,
          type: 'warning',
          confirmButtonText,
          cancelButtonText
        })
          .then(() => {
            this.$emit('close')
          })
          .catch(() => {})
      } else {
        this.$emit('close')
      }
    }
  }
}
</script>
